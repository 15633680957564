import * as roles from 'constants/enums/roles'

export default {
  [roles.MO]: 'Manažer obchodu',
  [roles.RSO]: 'Realitní specialista obchod',
  [roles.VP]: 'Vedoucí právník',
  [roles.ZVP]: 'Zástupce vedoucího právníka',
  [roles.P]: 'Právník',
  [roles.APO]: 'Asistentka právního oddělení',
  [roles.J]: 'Jednatel',
  [roles.UK]: 'Účetní kontrolor',
  [roles.A]: 'Admin',
} as const

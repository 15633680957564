/*
 *   Roles:
 *   business_manager - Manažer obchodu - MO
 *   business_real_estate_specialist - Realitní specialista obchod - RSO
 *   head_lawyer - Vedoucí právník - VP
 *   deputy_head_lawyer - Zástupce vedoucího právníka - ZVP
 *   lawyer - Právník - P
 *   legal_department_assistant - Asistentka právního oddělení - APO
 *   statutory - Jednatel - J
 *   accounting_supervisor - Účetní kontrolor - UK
 *   admin - Admin - A
 * */

export const MO = 'business_manager'
export const RSO = 'business_real_estate_specialist'
export const VP = 'head_lawyer'
export const ZVP = 'deputy_head_lawyer'
export const P = 'lawyer'
export const APO = 'legal_department_assistant'
export const J = 'statutory'
export const UK = 'accounting_supervisor'
export const A = 'admin'

export const ALL_ROLES = [MO, RSO, VP, ZVP, P, APO, J, UK, A] as const

export type Role = (typeof ALL_ROLES)[number]
